<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import OrdersTable from "@/components/shop/orders-table.vue";

/**
 * Orders Component
 */
export default {

  computed: {
    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  components: {
    Layout,
    PageHeader,
    OrdersTable
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('orders.title'),
          active: true
        }
      ]
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('orders.title')"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row my-2">
              <div class="col">
                <div>
                  <b-dropdown dropleft variant="info">
                    <template slot="button-content">{{ $t('orders.legend.button') }}</template>
                    <b-dropdown-item-button><span class="text-success">{{ $t('orders.legend.paid') }}</span></b-dropdown-item-button>
                    <b-dropdown-item-button><span class="text-danger">{{ $t('orders.legend.unpaid') }}</span></b-dropdown-item-button>
                    <b-dropdown-item-button><span class="text-warning">{{ $t('orders.legend.verification') }}</span></b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
            </div>

            <orders-table :for-user-only="true" />
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>